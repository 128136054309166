import React from 'react';
import SmartAPY from '../components/updated/SmartApy';

const SmartAPYPage = () => {
  return (
    <div>
      <SmartAPY />
    </div>
  );
};

export default SmartAPYPage;
