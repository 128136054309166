import React from 'react';
import HowToCreateBuySell from '../components/updated/Redeem/HowToCreateBuySell';

const HowToCreateBuySellPage = () => {
  return (
    <div>
      <HowToCreateBuySell />
    </div>
  );
};

export default HowToCreateBuySellPage;
