import React from 'react';
import CardShoppingHistory from '../components/updated/Redeem/CardShoppingHistory';

const GiftShoppingHistoryPage = () => {
  return (
    <div>
      <CardShoppingHistory />
      
    </div>
  );
};

export default GiftShoppingHistoryPage;
