import React from 'react';
import HowItWorks from '../components/updated/SmartApy/HowItWorks';
const HowItWorksSmartAPY = () => {
  return (
    <div>
      <HowItWorks />
    </div>
  );
};

export default HowItWorksSmartAPY;
