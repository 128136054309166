import React from 'react';
import CryptoTreasury from '../components/updated/CryptoTreasury';

const CryptoTreasuryPage = () => {
  return (
    <div>
      <CryptoTreasury />
    </div>
  );
};

export default CryptoTreasuryPage;
